import React from 'react';
import { FadeUpOnView } from "../CaseStudyBlocks"
import * as styles from './QuarterlyScrollFooter.module.scss'

const QuarterlyScrollFooter = () => {
  return (
    <FadeUpOnView>
      <div className={styles.container}>
        <div className={styles.header}>Say hi.</div>
        <a className={styles.link} href="mailto:hello@herman-scheer.com">hello@herman-scheer.com</a>
      </div>
    </FadeUpOnView>
  )
}

export default QuarterlyScrollFooter