import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import CaseStudyBlocks from "../components/CaseStudyBlocks"
import QuarterlyScrollFooter from "../components/QuarterlyScrollFooter"

const CaseStudyPage = ({ data }) => {
  const {
    contentfulQuarterlyScroll: { name, client, blocks, featuredImages },
  } = data

  const socialShareImage = featuredImages[0].images.sort((a, b) => {
    return (
      a.file.details.image.height / a.file.details.image.width -
      b.file.details.image.height / b.file.details.image.width
    )
  })[0]

  return (
    <FadeIn>
      <SEO
        title={`${client ? client[0].name : name} | Case Study`}
        socialShareImageUrl={socialShareImage.file.url}
      />
      <CaseStudyBlocks blocks={blocks} fixedHeightFirstBlock={false} />
      <QuarterlyScrollFooter />
    </FadeIn>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulQuarterlyScroll(slug: { eq: $slug }) {
      id
      name
      featuredImages {
        images {
          file {
            url
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
      blocks {
        id
        hideForDesktop
        hideForMobile
        hideForTablet
        mediaComponents {
          ... on ContentfulLottieComponent {
            id
            name
            jsonFile {
              file {
                url
              }
            }
            loop
            renderer
            autoplay
          }
          ... on ContentfulTextComponent {
            id
            text
          }
          ... on ContentfulVimeoComponent {
            vimeoId
            aspectRatio
          }
          ... on ContentfulResponsiveImage {
            id
            images {
              title
              gatsbyImageData(quality: 70)
              file {
                details {
                  size
                  image {
                    height
                    width
                  }
                }
              }
            }
            textOverlay {
              id
              childMarkdownRemark {
                id
                html
              }
            }
          }
        }
      }
    }
  }
`

export default CaseStudyPage
