// extracted by mini-css-extract-plugin
export var hideForMobile = "QuarterlyScrollFooter-module--hide-for-mobile--tsAnV";
export var hideForTablet = "QuarterlyScrollFooter-module--hide-for-tablet--1QOc2";
export var hideForLaptop = "QuarterlyScrollFooter-module--hide-for-laptop--Rnn78";
export var hideForDesktop = "QuarterlyScrollFooter-module--hide-for-desktop--6JIUc";
export var hideForExtraLarge = "QuarterlyScrollFooter-module--hide-for-extra-large--o9-yG";
export var showForMobileOnly = "QuarterlyScrollFooter-module--show-for-mobile-only--C1Sdc";
export var showForTabletOnly = "QuarterlyScrollFooter-module--show-for-tablet-only--+9bPp";
export var showForLaptopOnly = "QuarterlyScrollFooter-module--show-for-laptop-only--aJE0z";
export var showForDesktopOnly = "QuarterlyScrollFooter-module--show-for-desktop-only--+cI0-";
export var showForExtraLargeOnly = "QuarterlyScrollFooter-module--show-for-extra-large-only--emn4P";
export var fullPageContainer = "QuarterlyScrollFooter-module--full-page-container--ZZZDK";
export var container = "QuarterlyScrollFooter-module--container--f01yh";
export var header = "QuarterlyScrollFooter-module--header--RuJ0Z";
export var link = "QuarterlyScrollFooter-module--link--PTfez";